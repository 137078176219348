import { StudentProfileProvider, ApiWrapper, LocationValidator, InactivityTimeout } from '@papa-internal/components';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { theme } from './styles/theme';
import { QueryClient } from '@tanstack/react-query';
import { Suspense, lazy } from 'react';
import { FormLayout } from './Pages/Form/FormLayout';

const queryClient = new QueryClient();

// Form Pages
export const loadAboutYou = () => import('./Pages/Form/AboutYou');
export const loadEducationALR = () => import('./Pages/Form/EducationALR');
export const loadEducationUG = () => import('./Pages/Form/EducationUG');
export const loadFinalSteps = () => import('./Pages/Form/FinalSteps');
export const loadCompletion = () => import('./Pages/Form/Completion');
const AboutYou = lazy(loadAboutYou);
const EducationALR = lazy(loadEducationALR);
const EducationUG = lazy(loadEducationUG);
const FinalSteps = lazy(loadFinalSteps);
const Completion = lazy(loadCompletion);

// Account Pages
export const loadSignUp = () => import('./Pages/Account/SignUp');
export const loadConfirm = () => import('./Pages/Account/Confirm');
export const loadLogin = () => import('./Pages/Account/Login');
export const loadForgot = () => import('./Pages/Account/ForgotPassword');
export const loadReset = () => import('./Pages/Account/ResetPassword');
const SignUp = lazy(loadSignUp);
const Confirm = lazy(loadConfirm);
const Login = lazy(loadLogin);
const ForgotPassword = lazy(loadForgot);
const ResetPassword = lazy(loadReset);

function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <ApiWrapper.Root queryClient={queryClient}>
                    <StudentProfileProvider queryClient={queryClient}>
                        <InactivityTimeout.Root queryClient={queryClient} />
                        <LocationValidator.Root />
                        <Suspense>
                            <Routes>
                                <Route
                                    element={
                                        <FormLayout queryClient={queryClient}>
                                            <Outlet />
                                        </FormLayout>
                                    }
                                >
                                    <Route // FORM PAGES
                                        path="/about"
                                        element={<AboutYou queryClient={queryClient} />}
                                    />
                                    <Route path="/education-alr" element={<EducationALR queryClient={queryClient} />} />
                                    <Route path="/education-ug" element={<EducationUG queryClient={queryClient} />} />
                                    <Route path="/final-steps" element={<FinalSteps />} />
                                </Route>
                                <Route // DEFAULT PAGE
                                    element={<Navigate to={{ pathname: '/about' }} />}
                                    path="*"
                                />
                                <Route // ACCOUNT PAGES
                                    element={<SignUp />}
                                    path="/signup"
                                />
                                <Route element={<Confirm />} path="/confirm" />
                                <Route element={<Login />} path="/login" />
                                <Route element={<ForgotPassword />} path="/forgot" />
                                <Route element={<ResetPassword />} path="/reset" />

                                <Route // COMPLETION PAGE
                                    path="/completion"
                                    element={<Completion queryClient={queryClient} />}
                                />
                            </Routes>
                        </Suspense>
                    </StudentProfileProvider>
                </ApiWrapper.Root>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
