import styled from 'styled-components';
import { HorizontalNav, HorizontalNavMenuItem, IClientSnapshot } from '@papa/components';
import { loadAboutYou, loadEducationALR, loadEducationUG, loadFinalSteps } from '../App';

const StyledHorizontalNav = styled(HorizontalNav.Root)`
    &${HorizontalNav.Container} {
        background-color: ${props => props.theme.secondary.main};
        display: flex;
        flex-direction: row;
    }
    &${HorizontalNav.Container} > div {
        display: flex;
        justify-content: center;
        width: 100vw;
    }
    &${HorizontalNav.MenuList} {
        width: ${props => props.theme.layout.tablet};
        color: ${props => props.theme.neutral.faint};
        margin: 0;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
` as typeof HorizontalNav.Root;

const StyledHorizontalNavMenuItem = styled(HorizontalNavMenuItem.Root)`
    padding: 1rem 0rem;
    border-radius: 0;
    &${HorizontalNavMenuItem.Text} {
        font-family: ${props => props.theme.font.main};
        padding: 1rem;
        text-align: center;
    }
    &&${HorizontalNavMenuItem.Link} {
        color: ${props => props.theme.white};
        &.active {
            background-color: ${props => props.theme.primary.main};
        }
        height: 100%;
        width: 100%;

        &:hover {
            background-color: ${props => props.theme.primary.light};
        }
        &:focus {
            background-color: ${props => props.theme.primary.main};
        }
    }

    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        font-size: 1em;
    }
` as typeof HorizontalNavMenuItem.Root;

interface INavBarProps {
    clientSnapshot: IClientSnapshot;
}
export const NavBar = (props: INavBarProps) => {
    const { clientSnapshot } = props;

    // hides nav bar in pdf rendering
    if (clientSnapshot.is_service_user) return <></>;

    return (
        <>
            <StyledHorizontalNav>
                <StyledHorizontalNavMenuItem leadingIcon="face" route="/about" loader={loadAboutYou}>
                    About You
                </StyledHorizontalNavMenuItem>
                <StyledHorizontalNavMenuItem leadingIcon="auto_stories" route="/education-alr" loader={loadEducationALR}>
                    Education ALR
                </StyledHorizontalNavMenuItem>
                <StyledHorizontalNavMenuItem leadingIcon="backpack" route="/education-ug" loader={loadEducationUG}>
                    Education UG
                </StyledHorizontalNavMenuItem>
                <StyledHorizontalNavMenuItem leadingIcon="done" route="/final-steps" loader={loadFinalSteps}>
                    Final Steps
                </StyledHorizontalNavMenuItem>
            </StyledHorizontalNav>
        </>
    );
};
