import {
    Separator,
    ConditionOperators,
    IConditionObj,
    ThemeColor,
    ThemeShade,
    AccountPopover,
    ButtonWithIcon,
    IClientSnapshot,
    primitives,
    StudentWebStatusEnum,
} from '@papa/components';
import styled from 'styled-components';
import dropins from '../emails/dropins.json';
import { Blurb, StyledSeparator } from '../styles/FormElements';
import { FirstNameDropin } from './StudentDataDropins';
import { QueryClient } from '@tanstack/react-query';
import { loadLogin, loadSignUp } from '../App';
import { StudentAnswersKeys } from '../dataModel/types';

export const conditionIsLoggedIn: IConditionObj<string> = {
    valueKey: 'is_logged_in',
    operator: ConditionOperators.equals,
    target: true,
};

const StyledAcctPopover = styled(AccountPopover.Root)`
    z-index: 100;

    &${AccountPopover.Trigger} {
        font-size: 1em;
        margin-right: 3rem;
        @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
            margin-right: 1rem;
        }
    }

    ${AccountPopover.AccountTrigger} {
        gap: 1rem;
        @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
            gap: 0.5rem;
        }
    }

    &${AccountPopover.PopoverContainer} {
        background-color: ${props => props.theme.neutral.faint};
    }

    &${AccountPopover.AccountIcon} {
        padding: 0;
    }

    &${AccountPopover.SignInButton}, &${AccountPopover.SignUpButton} {
        box-shadow: none;
    }

    &${AccountPopover.SignInButton} {
        background-color: ${props => props.theme.primary.main};
    }

    &${AccountPopover.SignUpButton} {
        background-color: transparent;
    }

    &${AccountPopover.PopoverContainer} {
        left: 2rem;
    }

    &${AccountPopover.LogoutButton} {
        background-color: ${props => props.theme.primary.main};
        height: 2rem;
        box-shadow: none;

        &:disabled {
            background-color: ${props => props.theme.disabled.light};
        }

        &&&${ButtonWithIcon.LoadingContainer} {
            background-color: transparent;
            padding: 0 0.85rem;
        }
    }
`;
const BannerImage = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
    grid-column: 1 / span 9;
    grid-row: 1 / span 5;
`;
const ApplicationHeader = styled.div`
    display: flex;
    justify-content: space-between;
    height: 3.5rem;
    align-items: center;
    padding: 1rem 2rem;
    background-color: ${props => props.theme.neutral.faint};
    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        padding: 2% 5%;
    }
`;
const SchoolLogo = styled.img`
    object-fit: contain;
    height: 3.5rem;
    align-self: center;
`;
const AppTitle = styled.h2`
    color: ${props => props.theme.secondary.main};
    font-family: ${props => props.theme.font.main};
    width: 18rem;

    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        font-size: 1em;
    }
`;
const Banner = styled.div`
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(5, 1fr);
    position: relative;
    text-align: center;
`;
const WelcomeMessage = styled.div`
    border-radius: ${props => props.theme.borderRadius};
    background: ${props => props.theme.secondary.light}cc;
    color: ${props => props.theme.white};
    font-family: ${props => props.theme.font.display};
    padding: 1rem 1rem;
    grid-column: 6 / 9;
    grid-row: 2 / span 3;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @media screen and (max-width: calc(${props => props.theme.layout.tablet} + 300px)) and (min-width: ${props => props.theme.layout.mobile}) {
        grid-column: 2 / 9;
        grid-row: 2 / span 3;
    }

    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        grid-column: 1 / span 9;
        grid-row: 1 / span 5;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    gap: 1rem;
    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        max-width: 65vw;
    }
`;

const WelcomeTitle = styled.h2`
    font-family: ${props => props.theme.font.display};
    font-size: 2em;
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    color: ${props => props.theme.error.light};
    margin: 0;
    padding: 0.5;
`;

export const LogoAndTitle = () => {
    return (
        <LogoContainer>
            <SchoolLogo src="/eab-university.png" alt="School logo for Eabert University, which features Eabert holding a backpack" width="150" height="150" />
            <StyledSeparator orientation={Separator.Orientations.vertical} weight="thin" color={ThemeColor.primary} shade={ThemeShade.main} />
            <AppTitle>
                {dropins.partner_name} {dropins.application_title}
            </AppTitle>
        </LogoContainer>
    );
};

interface IWelcomeBannerProps {
    clientSnapshot: IClientSnapshot;
    studentAnswers: Record<StudentAnswersKeys, primitives>;
    hasWelcomeMessage?: boolean;
    queryClient: QueryClient;
}

export const WelcomeBanner = (props: IWelcomeBannerProps) => {
    const { clientSnapshot, queryClient, studentAnswers } = props;

    // hides the welcome banner in the PDF rendering
    if (clientSnapshot.is_service_user) return <></>;

    const isSubmitted = clientSnapshot.web_status === StudentWebStatusEnum.submitted;

    let title: string | JSX.Element = 'Welcome!';
    let blurb: string | JSX.Element = "Start applying now and we'll help you set up a password to secure your account.";

    // always show on thanks page; always show in preview mode; show on first page for first two logins.
    const shouldShowWelcomeMessage = isSubmitted || !clientSnapshot.is_logged_in || (!clientSnapshot.has_changed_page && (clientSnapshot.num_logins ?? 0) < 3);
    if (isSubmitted) {
        // completion page content
        title = (
            <>
                Thanks
                <FirstNameDropin studentAnswers={studentAnswers} />!
            </>
        );
        blurb = (
            <>
                Congratulations on completing the online portion of your application!
                <br />
                <br />
                Take these last steps to finish up your application.
            </>
        );
    } else if (clientSnapshot.num_logins === 1) {
        // welcome first content
        title = <>Welcome!</>;
        blurb = (
            <>
                {dropins.partner_name} is so glad to see you applying
                <FirstNameDropin studentAnswers={studentAnswers} />.
                <br />
                <br />
                We offer a variety of programs to help you achieve your goals. Early applicants have special access to unique scholarship opportunities.
                <br />
                <br />
                Learn more after you apply!
            </>
        );
    } else if (clientSnapshot.num_logins === 2) {
        // welcome back content
        title = (
            <>
                Welcome Back
                <FirstNameDropin studentAnswers={studentAnswers} />!
            </>
        );
        blurb = 'We are so glad to see you again. You can pick up where you left off.';
    }

    return (
        <div role="banner">
            <ApplicationHeader>
                <LogoAndTitle />
                <StyledAcctPopover queryClient={queryClient} loaders={{ signUp: loadSignUp, login: loadLogin }} />
            </ApplicationHeader>
            {shouldShowWelcomeMessage ? (
                <Banner>
                    <BannerImage src="/header.webp" alt="Zoomed in on a blue sky with green tree leaves on the right and a clear glass building on the left" />
                    <WelcomeMessage>
                        <WelcomeTitle>{title}</WelcomeTitle>
                        <Blurb fontSize="1.2em" color={ThemeColor.neutral} shade={ThemeShade.light}>
                            {blurb}
                        </Blurb>
                    </WelcomeMessage>
                </Banner>
            ) : undefined}
        </div>
    );
};
