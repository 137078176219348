import { primitives } from '@papa-internal/components';
import { StudentAnswersKeys } from '../dataModel/types';

interface IFirstNameProps {
    studentAnswers: Record<StudentAnswersKeys, primitives>;
}
export const FirstNameDropin = (props: IFirstNameProps) => {
    const { studentAnswers } = props;
    return <>{studentAnswers?.first_name ? `, ${studentAnswers.first_name}` : undefined}</>;
};
